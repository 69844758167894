"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var react_native_1 = require("react-native");
var getDimensions = function (type, getDimensions) {
    var _a = __read(react_1.useState(function () { return react_native_1.Dimensions.get(type); }), 2), dimensions = _a[0], setDimensions = _a[1];
    react_1.useEffect(function () {
        var handleDimensionsChange = function (_a) {
            var window = _a.window, screen = _a.screen;
            var newDimensions = getDimensions(window, screen);
            if (dimensions.height !== newDimensions.height ||
                dimensions.width !== newDimensions.width) {
                setDimensions(__assign({}, newDimensions));
            }
        };
        react_native_1.Dimensions.addEventListener('change', handleDimensionsChange);
        return function () {
            react_native_1.Dimensions.removeEventListener('change', handleDimensionsChange);
        };
    }, [react_native_1.Dimensions, dimensions]);
    return dimensions;
};
function useScreenDimensions() {
    return getDimensions('screen', function (_, screen) { return screen; });
}
exports.useScreenDimensions = useScreenDimensions;
;
function useWindowDimensions() {
    return getDimensions('window', function (window) { return window; });
}
exports.useWindowDimensions = useWindowDimensions;
;
function useDimensions() {
    var screen = useScreenDimensions();
    var window = useWindowDimensions();
    return { screen: screen, window: window };
}
exports.default = useDimensions;
;
