export const FILE_TYPE_MAP = {
  folder: ['folder'],
  folderOpen: ['folder-open'],
  ai: ['ai', 'eps'],
  app: ['app'],
  axure: ['rp'],
  book: [
    'mobi',
    'oeb',
    'lit',
    'xeb',
    'ebx',
    'rb',
    'pdb',
    'epub',
    'azw3',
    'hlp',
    'chm',
    'wdl',
    'ceb',
    'abm',
    'pdg',
    'caj',
  ],
  css: ['css', 'less', 'sass'],
  dmg: ['dmg'],
  excel: [
    'csv',
    'fods',
    'ods',
    'ots',
    'xls',
    'xlsm',
    'xlsx',
    'xlt',
    'xltm',
    'xltx',
  ],
  exe: ['exe'],
  html: ['htm', 'html', 'mht'],
  img: [
    'png',
    'bmp',
    'jpg',
    'jpeg',
    'gif',
    'webp',
    'tga',
    'exif',
    'fpx',
    'svg',
    'hdri',
    'raw',
  ],
  java: ['jar', 'java'],
  js: ['js'],
  json: ['json'],
  keynote: ['key'],
  md: ['md', 'markdown'],
  music: [
    'mp3',
    'cda',
    'wav',
    'wma',
    'ra',
    'mida',
    'ogg',
    'ape',
    'flac',
    'aac',
    'mpga',
  ],
  numbers: ['numbers'],
  pages: ['pages'],
  pdf: ['pdf'],
  php: ['php'],
  pkg: ['pkg'],
  ppt: [
    'fodp',
    'odp',
    'otp',
    'pot',
    'potm',
    'potx',
    'pps',
    'ppsm',
    'ppsx',
    'ppt',
    'pptm',
    'pptx',
  ],
  psd: ['psd'],
  python: ['python'],
  sh: ['sh'],
  sketch: ['sketch'],
  sql: ['sql'],
  text: ['text', 'txt'],
  video: ['mp4', 'avi', 'mov', 'rmvb', 'rm', 'flv', 'mpeg', 'wmv', 'mkv'],
  vue: ['vue'],
  word: [
    'doc',
    'docm',
    'docx',
    'dot',
    'dotm',
    'dotx',
    'epub',
    'fodt',
    'odt',
    'ott',
    'rtf',
  ],
  xmind: ['xmind'],
  zip: [
    'zip',
    'rar',
    'tar',
    'gz',
    'gzip',
    'uue',
    'bz2',
    'iso',
    '7z',
    'z',
    'ace',
    'lzh',
    'arj',
    'cab',
  ],
};

export const FILE_ICON_MAP = {
  folder: require('./img/folder.svg'),
  folderOpen: require('./img/folder-open.svg'),
  common: require('./img/common.svg'),
  ai: require('./img/ai.svg'),
  app: require('./img/app.svg'),
  axure: require('./img/axure.svg'),
  book: require('./img/book.svg'),
  css: require('./img/css.svg'),
  dmg: require('./img/dmg.svg'),
  excel: require('./img/excel.svg'),
  exe: require('./img/exe.svg'),
  html: require('./img/html.svg'),
  img: require('./img/img.svg'),
  java: require('./img/java.svg'),
  js: require('./img/js.svg'),
  json: require('./img/json.svg'),
  keynote: require('./img/keynote.svg'),
  md: require('./img/md.svg'),
  music: require('./img/music.svg'),
  numbers: require('./img/numbers.svg'),
  pages: require('./img/pages.svg'),
  pdf: require('./img/pdf.svg'),
  php: require('./img/php.svg'),
  pkg: require('./img/pkg.svg'),
  ppt: require('./img/ppt.svg'),
  psd: require('./img/psd.svg'),
  sketch: require('./img/sketch.svg'),
  python: require('./img/python.svg'),
  sh: require('./img/sh.svg'),
  sql: require('./img/sql.svg'),
  text: require('./img/text.svg'),
  video: require('./img/video.svg'),
  vue: require('./img/vue.svg'),
  word: require('./img/word.svg'),
  xmind: require('./img/xmind.svg'),
  zip: require('./img/zip.svg'),
};
